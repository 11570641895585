<template>
  <v-card style="margin-bottom:5em">
    <v-toolbar style="margin-bottom:1em">
      <v-toolbar-title>M.Ed. Registration Admin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-if="searchActive" ref="searchField" v-model="searchText" label="Search by Name or Banner ID" style="max-width:250px" prepend-inner-icon="fal fa-search" clear-icon="fal fa-times" hide-details clearable outlined @blur="searchBlur"></v-text-field>
      <v-tooltip v-if="!searchActive" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="activateSearch">
            <v-icon>fal fa-search</v-icon>
          </v-btn>
        </template>
        <span>Search for a student by name or Banner ID</span>
      </v-tooltip>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ selectedTerm }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="{ value, text } in terms" :key="'term-' + value" @click="selectedTerm = value">
            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
              <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-select v-model="filter" :items="filterOptions" label="Status Filter" solo hide-details style="max-width:250px" @change="loadData()"></v-select>
    </v-toolbar>
    <v-row>
      <v-col :md="4">
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-subtitle style="white-space:pre-wrap">List of students who have submitted their registration</v-list-item-subtitle>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="loadData()">
                      <v-icon>fal fa-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>Reload the list</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group v-model="selectedIndex">
              <v-list-item v-for="({ term, bannerId, legalName, regStatus, infoStatus, housingStatus }) in students" :key="term + '-' + bannerId">
                <v-list-item-content>
                  <v-list-item-title>{{ legalName }}</v-list-item-title>
                  <v-list-item-title>{{ bannerId }}</v-list-item-title>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-badge :icon="'fas fa-' + (regStatus === 'pending' ? 'exclamation' : 'check')" :color="regStatus === 'pending' ? 'error' : 'success'" bordered overlap style="margin-right:10px;margin-top:6px">
                          <v-chip v-on="on" dark small>Registration</v-chip>
                        </v-badge>
                      </template>
                      <span v-if="regStatus === 'pending'">Registration has not been entered into Banner</span>
                      <span v-else>Registration has been added to Banner</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-badge :icon="'fas fa-' + (infoStatus === 'pending' ? 'exclamation' : 'check')" :color="infoStatus === 'pending' ? 'error' : 'success'" bordered overlap style="margin-right:10px;margin-top:6px">
                          <v-chip v-on="on" dark small>Info Update</v-chip>
                        </v-badge>
                      </template>
                      <span v-if="infoStatus === 'pending'">Contact Info has not been updated in Banner</span>
                      <span v-else>Contact Info has been updated in Banner</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-badge :icon="'fas fa-' + (housingStatus === 'pending' ? 'exclamation' : 'check')" :color="housingStatus === 'pending' ? 'error' : 'success'" bordered overlap style="margin-right:10px;margin-top:6px">
                          <v-chip v-on="on" dark small>Housing</v-chip>
                        </v-badge>
                      </template>
                      <span v-if="housingStatus === 'pending'">Housing has not been processed</span>
                      <span v-else>Housing has been processed</span>
                    </v-tooltip>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col v-if="selectedIndex == null || selectedIndex < 0" :md="8">
        <v-card>
          <v-card-text>Select a student from the left to see their submitted information here.</v-card-text>
        </v-card>
      </v-col>
      <v-col v-else :md="8">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>{{ selected.submitted.legalName }} ({{ selected.submitted.bannerId }})</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title>Term: {{ selected.term }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col :cols="6">
                <v-card style="margin-bottom:.5em">
                  <v-card-text style="padding-bottom:0">
                    <h4>Specialization/Classification</h4>
                  </v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ selected.submitted.specialization === 'EL' ? 'Educational Leadership' : 'Integrated Curriculum and Instruction' }}</v-list-item-title>
                        <v-list-item-subtitle>Specialization</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="selected.submitted.specialization !== selected.specialization">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon color="error">fas fa-exclamation-circle</v-icon>
                          </template>
                          <span>Specialization changed from {{ selected.specialization }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ selected.submitted.classification }}</v-list-item-title>
                        <v-list-item-subtitle>Classification</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="parseInt(selected.submitted.classification.substring(0, 1)) !== selected.classification">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="error">fas fa-exclamation-circle</v-icon>
                          </template>
                          <span>Classification changed from {{ selected.classification }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
                <v-card style="margin-bottom:.5em">
                  <v-card-text>
                    <h4>School Name/Address</h4>
                    <div>{{ selected.submitted.school }}</div>
                    <div>{{ selected.submitted.schoolAddress }}</div>
                  </v-card-text>
                </v-card>
                <v-card v-if="selected.submitted.classification === '3rd Year Student'" style="margin-bottom:.5em">
                  <v-card-text>
                    <h4>Planning to graduate?</h4>
                    <div>{{ selected.submitted.graduating }}</div>
                  </v-card-text>
                </v-card>
                <v-card style="margin-bottom:.5em">
                  <v-card-text>
                    <h4>Course Registration</h4>
                    <v-list dense>
                      <v-list-item v-if="selected.submitted.weekOne !== '' && selected.submitted.weekOne !== 'No Selected Course'">
                        <v-list-item-subtitle>Week One</v-list-item-subtitle>
                        <v-list-item-title>{{ selected.submitted.weekOne }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="selected.submitted.weekTwo !== '' && selected.submitted.weekTwo !== 'No Selected Course'">
                        <v-list-item-subtitle>Week Two</v-list-item-subtitle>
                        <v-list-item-title>{{ selected.submitted.weekTwo }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="selected.submitted.weekThree !== '' && selected.submitted.weekThree !== 'No Selected Course'">
                        <v-list-item-subtitle>Week Three</v-list-item-subtitle>
                        <v-list-item-title>{{ selected.submitted.weekThree }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="selected.submitted.fallOnline !== '' && selected.submitted.fallOnline !== 'No Selected Course'">
                        <v-list-item-subtitle>Fall Online Course</v-list-item-subtitle>
                        <v-list-item-title>{{ selected.submitted.fallOnline }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="selected.submitted.capstone !== '' && selected.submitted.capstone !== 'No Selected Course'">
                        <v-list-item-subtitle>Capstone Course</v-list-item-subtitle>
                        <v-list-item-title>{{ selected.submitted.capstone }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn v-if="selected.regStatus === 'pending'" text color="primary" @click="process('regStatus')">Process Registration</v-btn>
                    <v-btn v-else disabled text>Registration Processed</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col :cols="6">
                <v-card style="margin-bottom:.5em">
                  <v-list>
                    <v-subheader>Student Contact Info</v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <div>{{ selected.submitted.address }}</div>
                        <div>{{ selected.submitted.city }}, {{ selected.submitted.state }} {{ selected.submitted.zip }} {{ selected.submitted.nation }}</div>
                      </v-list-item-content>
                      <v-list-item-action v-if="selected.submitted.address !== selected.address || selected.submitted.city !== selected.city || selected.submitted.state !== selected.state || selected.submitted.zip !== selected.zip">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="error">fas fa-exclamation-circle</v-icon>
                          </template>
                          <span>Address has been updated from:<br/>{{ selected.address }}<br/>{{ selected.city }}, {{ selected.state }} {{ selected.zip }} {{ selected.nation }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="(selected.submitted.home !== '' && selected.submitted.home != null) || (selected.home !== '' && selected.home != null)">
                      <v-list-item-content>
                        <v-list-item-title>{{ selected.submitted.home }}</v-list-item-title>
                        <v-list-item-subtitle>Home Phone</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="selected.home !== selected.submitted.home">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="error">fas fa-exclamation-circle</v-icon>
                          </template>
                          <span v-if="selected.home != null && selected.home !== ''">Home phone has been updated from {{ selected.home }}</span>
                          <span v-else>Home phone has been added</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="(selected.submitted.cell !== '' && selected.submitted.cell != null) || (selected.cell !== '' && selected.cell != null)">
                      <v-list-item-content>
                        <v-list-item-title>{{ selected.submitted.cell }}</v-list-item-title>
                        <v-list-item-subtitle>Cell Phone</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="selected.cell !== selected.submitted.cell">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="error">{{ selected.cell == null || selected.cell === '' ? '' : 'fas fa-exclamation-circle' }}</v-icon>
                          </template>
                          <span v-if="selected.cell != null && selected.cell !== ''">Cell phone has been updated from {{ selected.cell }}</span>
                          <span v-else>Cell phone has been added</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="(selected.submitted.work !== '' && selected.submitted.work != null) || (selected.work !== '' && selected.work != null)">
                      <v-list-item-content>
                        <v-list-item-title>{{ selected.submitted.work }}</v-list-item-title>
                        <v-list-item-subtitle>Business Phone</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="selected.work !== selected.submitted.work">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="error">fas fa-exclamation-circle</v-icon>
                          </template>
                          <span v-if="selected.work != null && selected.work !== ''">Business phone has been updated from {{ selected.work }}</span>
                          <span v-else>Business phone has been added</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-subheader>FERPA Privacy</v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>May information be released: {{ selected.submitted.ferpa }}</v-list-item-title>
                        <v-list-item-subtitle v-if="selected.submitted.ferpa === 'Yes'" style="margin-bottom:.5em;white-space:normal;">Academic Info: {{ selected.submitted.releaseAcad }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="selected.submitted.ferpa === 'Yes'" style="white-space:normal;">Financial Info: {{ selected.submitted.releaseFin }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader v-if="'ebill' in selected.submitted && selected.submitted.ebill.length > 0">E-bills (add to GOREMAL with code EBIL)</v-subheader>
                    <v-list-item v-for="({ name, email }) in selected.submitted.ebill" :key="'ebill-' + email">
                      <v-list-item-content>
                        <v-list-item-title>{{ name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-btn v-if="selected.infoStatus === 'pending'" text color="primary" @click="process('infoStatus')">Process Contact Info</v-btn>
                    <v-btn v-else disabled text>Contact Info Updated</v-btn>
                  </v-card-actions>
                </v-card>
                <v-card style="margin-bottom:.5em">
                  <v-card-text>
                    <h4>Housing Details</h4>
                    <div>When Needed: {{ selected.submitted.housingWeeks }}</div>
                    <div>Room Preference: {{ selected.submitted.housingPref }}</div>
                    <template v-if="'room1' in selected.submitted && Array.isArray(selected.submitted.room1) && selected.submitted.room1.length > 0">
                      <h4>Room 1:</h4>
                      <div v-for="(name, index) in selected.submitted.room1" :key="'room1-' + index" style="margin-left:2em">{{ name === '' ? '**No Roommate Preference**' : name }}</div>
                    </template>
                    <template v-if="'room2' in selected.submitted && Array.isArray(selected.submitted.room2) && selected.submitted.room2.length > 0">
                      <h4>Room 2:</h4>
                      <div v-for="(name, index) in selected.submitted.room2" :key="'room2-' + index" style="margin-left:2em">{{ name === '' ? '**No Suitemate Preference**' : name }}</div>
                    </template>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn v-if="selected.housingStatus === 'pending'" text color="primary" @click="process('housingStatus')">Process Housing</v-btn>
                    <v-btn v-else disabled text>Housing Processed</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const isRecords = computed(() => 'Records' in roles.value)
    const students = ref([])
    const terms = ref([])
    const selectedTerm = ref('')
    const selectedIndex = ref(-1)
    const selected = computed(() => selectedIndex.value != null && selectedIndex.value >= 0 ? students.value[selectedIndex.value] : {})

    // function resetSelection () {
    //   selectedIndex.value = -1
    // }

    onMounted(async () => {
      // Get possible terms from the term warehouse
      let dt = new Date()
      dt.setDate(dt.getDate() - 90)
      let { data: termData } = await root.$feathers.service('system/term').find({ query: { term: { $regex: '[0-9]{4}55' }, end: { $gt: dt } } })
      for (let i = 0; i < termData.length; i++) {
        terms.value.push({ value: termData[i].term, text: termData[i].label })
      }
      selectedTerm.value = termData[0].term
      loadData()
      if (searchText.value !== '' && searchText.value != null) searchActive.value = true
    })
    watch(selectedTerm, () => {
      loadData()
    })

    const searchActive = ref(false)
    const searchField = ref(null)
    const searchText = ref('')

    function activateSearch () {
      searchActive.value = true
      root.$nextTick(() => searchField.value.focus())
    }

    function searchBlur () {
      if (searchText.value === '' || searchText.value == null) {
        searchActive.value = false
      }
    }

    const filter = ref('regStatus:pending')
    const filterOptions = ref([
      { value: 'regStatus:pending', text: 'Registration: Pending' },
      { value: 'regStatus:processed', text: 'Registration: Processed' },
      { value: 'infoStatus:pending', text: 'Contact Info: Pending' },
      { value: 'infoStatus:processed', text: 'Contact Info: Processed' },
      { value: 'housingStatus:pending', text: 'Housing Info: Pending' },
      { value: 'housingStatus:processed', text: 'Housing Info: Processed' }
    ])

    watch([searchText, filter], () => {
      loadData()
    })

    async function loadData () {
      selectedIndex.value = -1
      const page = 1
      const itemsPerPage = 20
      let query = {
        term: selectedTerm.value,
        regStatus: { $exists: true },
        $limit: itemsPerPage,
        $skip: (itemsPerPage * (page - 1))
      }
      if (searchText.value !== '' && searchText.value != null) {
        if (searchText.value.substr(0, 1) === '@') {
          query.bannerId = { $regex: searchText.value }
        } else {
          query.legalName = { $regex: searchText.value, $options: 'i' }
        }
      }
      let temp = filter.value.split(':')
      query[temp[0]] = temp[1]
      students.value = []
      let { data } = await root.$feathers.service('med/registration').find({ query })
      students.value = data
    }

    function process (field) {
      console.log(selected.value._id)
      root.$feathers.service('med/registration').patch(selected.value._id, { [field]: 'processed' }).then((data) => {
        students.value.splice(selectedIndex.value, 1, data)
      })
    }

    return {
      user,
      roles,
      isRecords,
      students,
      selectedTerm,
      terms,
      selectedIndex,
      selected,
      stringFormatDate,
      searchActive,
      searchField,
      searchText,
      activateSearch,
      searchBlur,
      filter,
      filterOptions,
      loadData,
      process
    }
  }
}
</script>
